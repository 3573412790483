/* VideoPlayer.css */

.container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.heading {
    font-size: 2rem;
    margin-bottom: 20px;
}

.input {
    padding: 10px;
    font-size: 1rem;
    margin-right: 10px;
}

.button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
}

.error {
    color: red;
    margin-top: 10px;
}

.videoContainer {
    margin-top: 20px;
}

.videoWrapper {
    margin-bottom: 20px;
}

.videoTitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.video {
    border: 1px solid #000;
    border-radius: 8px;
}
