/* Apply box-sizing universally */
/* *, *::before, *::after {
  box-sizing: border-box;
} */

html, body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.container {
  position: fixed;
  top: -20px;
  left: -21px;
  right: -21px;
  height: 100vh;
  background-color: black;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.top_live_screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: black;
  overflow: hidden;
}

.bottom_tabs_action {
  position: absolute;
  width: 100%;
  height: 12.5vh; 
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.mic_on {
  position: absolute;
  background-color: #000;
  color: #fff;
  border: none;
  bottom: 22px;
  left: 30px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch_camera {
  position: absolute;
  background-color: #000;
  color: #fff;
  border: none;
  bottom: 22px;
  right: 30px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.end-stream {
  position: absolute;
  width: 9vh; 
  height: 9vh; 
  bottom: 15px;
  left: 50%;
  border-radius: 50%;
  background-color: #000;
  color: white;
  border: 1px solid #bc8a01;
  font-size: 2vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  transform: translateX(-50%);
}
/* 

.end-stream:hover {
  background-color: #000;
}

.end-stream:focus {
  outline: none;
}

.end-stream:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}







.mic_on:hover, .switch_camera:hover {
  background-color: #000;
} */

/* Small Devices (Phones, max-width: 600px) */
/* @media (max-width: 600px) {
  .container {
    padding: 0;
    border-radius: 0;
  }

  .video {
    border-radius: 5px;
  }

  .button {
    padding: 8px 16px;
  }

  .end-stream {
    width: 9vh;
    height: 9vh;
    font-size: 2vh;
  }

  .bottom_tabs_action {
    padding: 5px;
    gap: 10px;
  }

  .mic_on, .switch_camera {
    padding: 8px 16px;
    font-size: 4vh;
  }
} */

/* Medium Devices (Tablets, max-width: 768px) */
/* @media (max-width: 768px) {
  .container {
    padding: 0;
  }

  .button {
    padding: 9px 18px;
  }

  .end-stream {
    width: 9vh;
    height: 9vh;
    font-size: 2vh;
  }

  .bottom_tabs_action {
    gap: 15px;
  }
} */

/* Large Devices (Laptops, max-width: 1024px) */
/* @media (max-width: 1024px) {
  .container {
    padding: 0;
  }

  .button {
    padding: 10px 20px;
  }

  .end-stream {
    width: 9vh;
    height: 9vh;
    font-size: 2vh;
  }

  .bottom_tabs_action {
    gap: 20px;
  }
} */

/* Extra Large Devices (Desktops, min-width: 1200px) */
/* @media (min-width: 1200px) {
  .container {
    padding: 0;
  }

  .button {
    padding: 12px 24px;
  }

  .end-stream {
    width: 9vh;
    height: 8vh;
    font-size: 2vh;
  }

  .bottom_tabs_action {
    gap: 25px;
  }
} */

/* EndStreamPage.css */
.end-stream-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  color: white;
  margin: 0;
}
